import { COLORS } from '../constants/style.constants';

export const isAuthLocation = () => window.location.pathname.includes('auth');

export const appScrollbar = `
     &::-webkit-scrollbar-track {
          opacity: 0;
          background-color: transparent;
     }

     &::-webkit-scrollbar {
          opacity: 0;
          width: 4px;
          background-color: transparent;
     }

     &::-webkit-scrollbar-thumb {
          border-radius: 4px;
          opacity: 0;
          background-color: transparent;
     }

     &::-webkit-scrollbar-thumb:hover {
          opacity: 0;
          background-color: transparent;
     }

     &:hover {
          &::-webkit-scrollbar-track {
               opacity: 1;
               background-color: ${COLORS.WHITE};
          }

          &::-webkit-scrollbar {
               border-radius: 4px;
               opacity: 1;
               width: 4px;
               background-color: ${COLORS.WHITE};
          }

          &::-webkit-scrollbar-thumb {
               border-radius: 4px;
               opacity: 1;
               background-color: #d9dde7;
          }

          &::-webkit-scrollbar-thumb:hover {
               opacity: 1;
               background-color: #d8dce6;
          }
     }
`;
