import React from 'react';
import { Form } from 'react-bootstrap';
import { Errors } from './index';

export const Input = ({
     disabled = false,
     type,
     field,
     values,
     placeholder,
     handleChange,
     touched,
     errors,
}) => (
     <>
          <Form.Control
               type={type}
               name={field}
               placeholder={placeholder}
               value={values[`${field}`]}
               onChange={handleChange}
               isInvalid={touched[`${field}`] && !!errors[`${field}`]}
               disabled={disabled}
          />
         <Errors touched={touched[`${field}`]} errors={errors[`${field}`]} />
     </>
);
