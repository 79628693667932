import React, { useState, useEffect, useRef } from 'react';
import { LoadingBarWrapper } from './LoadingBar.style';

export const LoadingBar = ({ finish }) => {
     const ref = useRef();
     const [complete, setComplete] = useState(0);

     useEffect(() => {
          let currentValue = complete;

          const intervalID = setInterval(() => {
               const random = Math.floor(Math.random() * Math.floor(40));
               currentValue += random;

               setComplete(currentValue);

               if (finish || currentValue >= 90) {
                    if (finish) {
                         setComplete(100);

                         let loader = ref.current;
                         loader.style.opacity = 0;

                         ref.current = loader;

                         return clearInterval(intervalID);
                    }

                    setComplete(90);
               }
          }, 200);

          return () => clearInterval(intervalID);
     }, [finish, complete]);

     return <LoadingBarWrapper ref={ref} width={complete} />;
};
