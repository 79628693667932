import React from "react"
import ContentLoader from "react-content-loader"

export const AvatarLoader = ({ width = 38, height = 38, ...restProps }) => (
    <ContentLoader
        speed={2}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...restProps}
    >
        <rect x="0" y="0" rx="3" ry="3" width={width} height={height} />
    </ContentLoader>
);
