import styled from 'styled-components';
import {
     BORDER_THEMED,
     FONT,
     COLORS,
     SIZE,
} from '../../../constants/style.constants';

export const SideBarWrapper = styled.aside`
     display: flex;
     flex-direction: column;
     box-shadow: 0px 0px 0px 0px #f7f8fa;
     background-color: ${COLORS.WHITE};
     margin-bottom: 20px;
     padding: 0 25px;

     &.card {
          border-radius: 4px;
          ${BORDER_THEMED};

          &-title {
               margin: 0;
               padding: 21px 25px;
               font-size: ${SIZE.LARGE};
               font-weight: ${FONT.MEDIUM};
               color: ${COLORS.DARK_BLUE};
               border-bottom: 1px solid ${COLORS.LIGHTER_BLUE};
          }

          .card-body {
               padding: 21px 25px;
          }
     }

     &.container {
          padding: 25px;
     }

     img {
          border-radius: 3px;
          margin-right: 22.4px;
     }

     .sidebar-widget__head {
          display: flex;
          align-items: center;
          padding-bottom: 29.4px;
          flex-wrap: nowrap;
          
          img {
            align-self: baseline;
          }

          .sidebar-widget__content {
               display: flex;
               flex-direction: column;
               max-width: 190px;
               
               a {
                    font-size: ${SIZE.HUGER};
                    color: ${COLORS.DARK_BLUE};
                    font-weight: ${FONT.MEDIUM};
                    text-decoration: none;
               }

               a:hover {
                    color: ${COLORS.BLUE};
                    transition: color 0.3s ease;
               }

               .sidebar-widget__title {
                    font-size: ${SIZE.HUGER};
                    color: ${COLORS.DARK_BLUE};
                    font-weight: ${FONT.MEDIUM};
               }

               .sidebar-widget__subtitle {
                    padding: 0.25rem 0 0 0;
                    font-weight: ${FONT.REGULAR};
                    color: ${COLORS.TEXT_GREY};
                    font-size: ${SIZE.INCREASED};
                    max-width: 190px;
               }
          }
     }

     .sidebar-widget__items {
          .sidebar-widget__item {
               display: flex;
               align-items: center;
               justify-content: start;
               /* padding: 0.9rem 1.05rem 0.9rem 0.6rem; */
               /* margin: 0.4rem 0; */
               padding: 15px 10px;
               margin: 4px 0;
               text-decoration: none;
               background-color: transparent;
               font-size: ${SIZE.NORMAL};

               &.sidebar-widget__item--active {
                    transition: color 0.3s ease;
                    background: ${COLORS.LIGHT_BLUE};
                    /* border-radius: 4px; */
               }

               .sidebar-widget__section {
                    color: ${COLORS.TEXT_GREY};
                    display: flex;
                    align-items: center;
                    height: 24px;

                    span {
                         display: flex;
                         align-items: center;
                         height: inherit;
                    }
               }
               .kt-svg-icon {
                    margin-right: 14px;
                    background-color: transparent;
               }

               &:hover,
               &.active {
                    transition: color 0.3s ease;
                    background: ${COLORS.PALE_BLUE};
                    color: ${COLORS.BLUE};
                    cursor: pointer;
                    text-decoration: none;
                    border-radius: 5px;
                    .sidebar-widget__section {
                         color: ${COLORS.BLUE};
                    }

                    .kt-svg-icon g [fill] {
                         fill: ${COLORS.BLUE};
                    }
               }
          }
     }

     @media only screen and (max-width: 1023px) {
          &.card {
               &-title {
                    font-size: 13.2px;
               }
          }

          .sidebar-widget__head {
               flex-wrap: wrap;
               justify-content: center;
          }

          .sidebar-widget__content {
               align-items: center;
          }

          .sidebar-widget__subtitle {
               font-size: ${SIZE.TINY} !important;
          }

          .sidebar-widget__items {
               .sidebar-widget__item {
                    font-size: ${SIZE.TINY} !important;
               }
          }

          img {
               border-radius: 3px;
               margin-right: 0 !important;
               margin-bottom: 20px;
          }
     }
`;
