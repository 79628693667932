import React from 'react';
import { Form } from 'react-bootstrap';
import isArray from "underscore/modules/isArray";

export const Errors = ({ touched, errors}) => {
    if (touched && errors) {

        return (
            <Form.Control.Feedback type="invalid">
                {isArray(errors) && (
                    errors.map((error) => {
                        return <p>{error}</p>;
                    })
                )}
                {!isArray(errors) && errors}
            </Form.Control.Feedback>
        );
    }

    return null;
};
