import Swal from 'sweetalert2';
import {COLORS} from "../../../constants/style.constants";

export const SweetAlert = ({
     title,
     text,
     confirmButtonText,
     cancelButtonText,
     showCancelButton,
     showCloseButton,
     callback,
}) => {
     const styledSwalButtons = Swal.mixin({
          customClass: {
               confirmButton: 'btn btn-primary',
               cancelButton: 'btn btn-light',
          },
          buttonsStyling: false,
     });

     styledSwalButtons
          .fire({
               title,
               text,
               icon: 'error',
               confirmButtonText,
               cancelButtonText,
               showCancelButton,
               showCloseButton,
               reverseButtons: true,
               iconColor: COLORS.RED
          })
          .then((result) => {
               if (result.value) {
                    callback();
               }
          });
};
