import React, { useState } from 'react';
import { StyledAlert } from './Alert.style';

export const Alert = ({
     children,
     variant = 'primary',
     dismissible = false,
}) => {
     const [show, setShow] = useState(true);

     const renderAlert = () => (
          <StyledAlert variant={variant}>{children}</StyledAlert>
     );

     const renderDismissibleAlert = () =>
          show && (
               <StyledAlert
                    dismissible
                    variant={variant}
                    onClose={() => {
                         setShow(false);
                    }}
               >
                    {children}
               </StyledAlert>
          );

     return dismissible ? renderDismissibleAlert() : renderAlert();
};
