import styled from 'styled-components';
import { COLORS } from '../../../constants/style.constants';
import { appScrollbar } from '../../../utils/style.utils';

export const RegularDrawer = styled('div')`
     position: fixed;
     background-color: rgba(0, 0, 0, 0.1);
     top: 0px;
     bottom: 0;
     left: 0;
     right: 0;
     z-index: 1000;

     .drawer-body {
          animation: slide-in 0.15s ease;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: #fff;
          box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.75);
          overflow: auto;
          max-width: 275px;
          z-index: 1001;

          transition: all 0.15 ease;

          /* Scrollbar */
          ${appScrollbar}
     }

     .drawer-toolbar {
          display: flex;
          flex-direction: row-reverse;
          width: 100%;

          .close-btn {
               background: transparent;
               display: flex;
               align-self: center;
               justify-content: center;
               height: 25px;
               width: 25px;

               i {
                    color: ${COLORS.PLACEHOLDER_GREY};
                    line-height: inherit;

                    &:hover {
                         color: ${COLORS.BLUISH};
                    }
               }
          }
     }

     @keyframes slide-in {
          0% {
               left: -260px;
          }

          100% {
               left: 0;
          }
     }
`;

export const DrawerWrapper = styled('div')`
     position: fixed;
     top: 0;
     bottom: 0;
     left: 0;
     right: 0;
     background-color: rgba(0, 0, 0, 0.1);
     z-index: 1000;

     .drawer-body {
          animation: slide-in 0.15s ease;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: #fff;
          box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.75);
          overflow: auto;
          max-width: 260px;
          z-index: 1001;

          transition: all 0.15 ease;

          /* Scrollbar */
          ${appScrollbar}
     }

     .drawer-toolbar {
          display: flex;
          flex-direction: row-reverse;
          width: 100%;

          .close-btn {
               background: transparent;
               display: flex;
               align-self: center;
               justify-content: center;
               height: 25px;
               width: 25px;

               i {
                    color: ${COLORS.PLACEHOLDER_GREY};
                    line-height: inherit;

                    &:hover {
                         color: ${COLORS.BLUISH};
                    }
               }
          }
     }

     @keyframes slide-in {
          0% {
               left: -260px;
          }

          100% {
               left: 0;
          }
     }
`;
