import React from 'react';
import logoBlue from '../../../assets/images/logo_bg-white.svg';
import logoWhite from '../../../assets/images/logo_bg-blue.svg';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const Logo = ({ white }) => {
     const { t } = useTranslation();
     return (
          <Link to="/dashboard">
               <img src={white ? logoWhite : logoBlue} alt={t('logo')} />
          </Link>
     );
};
