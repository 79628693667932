import { useState } from 'react';

export const useTransition = () => {
     const [transition, setTransition] = useState(false);

     const toggleTransition = () => {
          setTransition(!transition);
     };

     return { transition, toggleTransition };
};

export const useSecondTransition = () => {
     const [secondTransition, setSecondTransition] = useState(false);

     const toggleSecondTransition = () => {
          setSecondTransition(!secondTransition);
     };

     return { secondTransition, toggleSecondTransition };
};
