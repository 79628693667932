import styled from 'styled-components';
import {
     BORDER_THEMED,
     FONT,
     COLORS,
     SIZE,
} from '../../../constants/style.constants';

export const PortletWrapper = styled.div`
     .card {
          border-radius: 4px;
          ${BORDER_THEMED};

          &-title {
               margin: 0;
               padding: 21px 25px;
               font-size: ${SIZE.LARGE};
               font-weight: ${FONT.MEDIUM};
               color: ${COLORS.DARK_BLUE};
               border-bottom: 1px solid ${COLORS.LIGHTER_BLUE};
          }

          .card-body {
               padding: 21px 25px;
          }
     }

     @media only screen and (max-width: 1023px) {
          width: 100%;

          .card-title {
               padding: 15px !important;
               font-size: ${SIZE.TINY};
          }

          .card-body {
               padding: 15px !important;

               form {
                    margin: 0 !important;
                    min-width: 100%;
               }
          }
     }
`;
