import React from 'react';

export default function SearchIcon({ activeColor }) {
     return (
          <svg
               xmlns="http://www.w3.org/2000/svg"
               width="16"
               height="17"
               fill="none"
               viewBox="0 0 16 17"
               className="kt-svg-icon"
          >
               <path
                    fill="#000"
                    fillOpacity="0.12"
                    d="M10.293 12.707a1 1 0 111.414-1.414l4 4a1 1 0 01-1.414 1.414l-4-4z"
               ></path>
               <path
                    fill={activeColor}
                    fillRule="evenodd"
                    d="M0 7a7 7 0 1014 0A7 7 0 000 7zm12 0A5 5 0 112 7a5 5 0 0110 0z"
                    clipRule="evenodd"
               ></path>
          </svg>
     );
}
