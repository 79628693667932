import React from 'react';
import { NavLink } from 'react-router-dom';

export const SideBarLink = ({ to, icon, text }) => (
     <NavLink to={to} activeClassName="active" className="sidebar-widget__item">
          <div className="sidebar-widget__section">
               <span>{icon}</span>
               <span>{text}</span>
          </div>
     </NavLink>
);
