import styled from 'styled-components';
import { COLORS, FONT, SIZE } from '../../../constants/style.constants';

export const StyledPictureUploader = styled.div`
     position: relative;
     padding-bottom: 25px;

     .avatar {
          position: relative;
          display: inline-block;

          .avatar__holder {
               width: 120px;
               height: 120px;
               border-radius: 3px;
               /* border: 3px solid ${COLORS.WHITE}; */
               /* box-shadow: 0px 0px 13px 0px #e6e6e6; */
               background-repeat: no-repeat;
               background-size: cover;
               img{
                    border-radius: 3px;
               }
          }

          .avatar__upload {
               cursor: pointer;
               display: flex;
               align-items: center;
               justify-content: center;
               position: absolute;
               right: -10px;
               top: -10px;
               width: 30px;
               height: 30px;
               border-radius: 50%;
               background-color: #fff;
               box-shadow: 0px 0px 13px 0px #e6e6e6;

               input {
                    width: 0 !important;
                    height: 0 !important;
                    overflow: hidden;
                    opacity: 0;
               }

               i {
                    font-size: 0.9rem;
               }

               &:hover {
                    background-color: ${COLORS.BLUE};

                    i {
                         color: ${COLORS.WHITE};
                    }
               }
          }

          .avatar__cancel {
               cursor: pointer;
               align-items: center;
               justify-content: center;
               position: absolute;
               top: auto;
               right: -10px;
               bottom: -5px;
               width: 30px;
               height: 30px;
               border-radius: 50%;
               background-color: #ffffff;
               box-shadow: 0px 0px 13px 0px #e6e6e6;
               display: flex;

               svg {
                    color: ${COLORS.BLUE};
                    height: 14px;
                    width: 14px;
               }

               &:hover {
                    background-color: ${COLORS.BLUE};

                    svg {
                         color: ${COLORS.WHITE};
                    }
               }
          }
     }
     .recommended-size {
          position: absolute;
          width: 290px;
          font-weight: ${FONT.REGULAR};
          font-size: ${SIZE.SMALL};
          line-height: 17px;
          color: #8492a5;
          margin-top: 5px;
     }

     @media only screen and (max-width: 1023px) {
          .recommended-size {
               font-size: ${SIZE.MICRO};
          }
     }
`;
