import React from 'react';
import { SideBarLink } from './SideBarLink';
import { SideBarWrapper } from './SideBar.style';

export const SideBar = ({ picture, title, subtitle, links, style }) => (
     <SideBarWrapper className="card container" style={style}>
          <div className="sidebar-widget__head">
               {picture}
               <div className="sidebar-widget__content">
                    {/* eslint-disable-next-line */}
                    <span className="sidebar-widget__title">{title}</span>
                    <span className="sidebar-widget__subtitle">{subtitle}</span>
               </div>
          </div>
          <div className="sidebar-widget__items">
               {links.map(({ to, icon, text }) => (
                    <SideBarLink key={to} to={to} icon={icon} text={text} />
               ))}
          </div>
     </SideBarWrapper>
);
