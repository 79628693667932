import { getToken } from '../services/instance';

export const AVATAR_FILE_LIMIT = 5 * 1024 * 1024; // 5MB
export const SPEAKER_FILE_LIMIT = 100 * 1024 * 1024; // 5MB
export const EMAILS_FILE_LIMIT = 5 * 1024 * 1024; // 5MB

export const toDataURL = (url, callback) => {
     let xhr = new XMLHttpRequest();
     xhr.onload = function () {
          let reader = new FileReader();
          reader.onloadend = function () {
               callback(reader.result);
          };
          reader.readAsDataURL(xhr.response);
     };
     xhr.open('GET', url);
     xhr.setRequestHeader('Authorization', `Bearer ${getToken()}`);
     xhr.responseType = 'blob';
     xhr.send();
};

export const isValidFilesSize = (files) => {
     const filesSize = Array.from(files)
          .map(({ size }) => size)
          .reduce((acc, val) => acc + val);

     if (filesSize > 10_000_000) return false;

     return true;
};

export const isValidFileSize = (file, limit) => {
     return file.size <= limit;
};

export const convertBytesToMb = (bytes) => {
     return bytes / 1024 / 1024;
};
