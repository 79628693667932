import React, { memo, useState } from 'react';
import SearchIcon from '../../../assets/icons/metronic/search-icon';
import { COLORS } from '../../../constants/style.constants';
import { Trans, useTranslation } from 'react-i18next';

export const TablePanel = memo(
     ({
          title,
          total,
          actions,
          customTotal = null,
          customOptions = null,
          handleSearch = () => {},
     }) => {
          const [focused, setFocused] = useState(false);
          const { t } = useTranslation();

          return (
               <div className="table-actions">
                    <div className="table-content">
                         <div className="heading">{title}</div>
                         {customTotal ? (
                              <div className="results">{customTotal}</div>
                         ) : (
                              <div className="results"><Trans i18nKey='total' values={{ number: total }} /></div>
                         )}
                         {customOptions ? (
                              customOptions
                         ) : (
                              <div className="search-box">
                                   <input
                                        type="text"
                                        name="table-search"
                                        placeholder={`${t('search_more')}`}
                                        onChange={handleSearch}
                                        onFocus={() => setFocused(true)}
                                        onBlur={() => setFocused(false)}
                                   />
                                   <span>
                                        <SearchIcon
                                             activeColor={
                                                  focused
                                                       ? COLORS.BLUE
                                                       : COLORS.LIGHTER_BLUE
                                             }
                                        />
                                   </span>
                              </div>
                         )}
                    </div>
                    {actions}
               </div>
          );
     }
);
