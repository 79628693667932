import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { i18nextPlugin } from 'translation-check'

import enTranslations from './translations/en';
import ltTranslations from './translations/lt';

const resources = {
     en: {
          translation: enTranslations,
     },
     lt: {
          translation: ltTranslations,
     },
};

const currentTranslation = () =>
     localStorage.getItem('translation')
          ? localStorage.getItem('translation')
          : localStorage.setItem('translation', 'en');

i18n.use(initReactI18next).use(i18nextPlugin).init({
     resources,
     lng: currentTranslation(),
     fallbackLng: 'en',

     interpolation: {
          escapeValue: false,
     },
});

export default i18n;
