import React, { memo, useState, useRef, useEffect, useCallback } from 'react';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.min.css';

export const ImageCropper = memo((props) => {
     const { src, handleSetCropper } = props;

     const MAX_ZOOM = 5;

     const [zoomValue, setZoomValue] = useState(0);
     const [zoomMinValue, setZoomMinValue] = useState(0);
     const [centerVisible, setCenterVisible] = useState(true);

     const ImageHolder = useRef();
     const imgContainer = useRef();
     // Use cropper outside of an effect
     const [cropper, setCropper] = useState(null);

     useEffect(() => {
          const cropper = new Cropper(ImageHolder.current, {
               viewMode: 0,
               dragMode: 'move',
               guides: false,
               center: true,
               highlight: true,
               movable: true,
               rotatable: true,
               scalable: true,
               zoomable: true,
               zoomOnTouch: true,
               background: true,
               responsive: true,
               checkCrossOrigin: true,
               cropBoxMovable: false,
               cropBoxResizable: false,
               toggleDragModeOnDblclick: true,
               aspectRatio: 1,
               zoomOnWheel: false,
               checkOrientation: true,
               imageSmoothingEnabled: true,
               imageSmoothingQuality: 'high',
               ready() {
                    const imageData = cropper.imageData;
                    const cropData = cropper.getCropBoxData();

                    const minSliderZoom = cropData.height / imageData.naturalHeight;

                    setZoomMinValue(minSliderZoom);
                    setZoomValue(minSliderZoom);

                    cropper.zoomTo(minSliderZoom);

                    setTimeout(() => {
                         imgContainer.current.classList.remove('dont-show-image');
                    }, 200);
               }
          });

          setCropper(cropper);
          handleSetCropper(cropper);

          return () => {
               cropper.destroy();
          };
     }, [src]);

     const handleHideCenter = useCallback(() => {
          if (centerVisible) {
               setCenterVisible(false);
          }
     }, [centerVisible]);

     const handleZoomChange = useCallback(
          ({ target: { value } }) => {
               const parsedValue = parseFloat(value);
               setZoomValue(parsedValue);

               if (cropper) {
                    cropper.zoomTo(parsedValue);
               }
          },
          [cropper]
     );

     const zoomIn = () => {
          const newValue = zoomValue >= MAX_ZOOM ? MAX_ZOOM : zoomValue + 0.01;

          if (cropper) {
               cropper.zoomTo(parseFloat(newValue));
          }

          setZoomValue(newValue);
     };

     const zoomOut = () => {
          const newValue = zoomValue <= zoomMinValue ? zoomMinValue : zoomValue - 0.01;

          if (cropper) {
               cropper.zoomTo(parseFloat(newValue));
          }

          setZoomValue(newValue);
     };

     return (
          <>
               <div
                    className="img-container dont-show-image"
                    ref={imgContainer}
                    style={{ position: 'relative' }}
                    onClick={handleHideCenter}
               >
                    <img ref={ImageHolder} src={src} alt="Source" />
                    <div className="cropper-center">
                         <div
                              className={`img-tooltip ${
                                   centerVisible ? '' : 'hidden'
                              }`}
                         >
                              &#x2725; drag to reposition
                         </div>
                    </div>
               </div>
               <div className="zoom-slider">
                    <button
                         className="minus-button minus-button--small"
                         onClick={zoomOut}
                    />
                    <input
                         type="range"
                         min={zoomMinValue}
                         max={MAX_ZOOM}
                         step="0.0001"
                         value={zoomValue}
                         onChange={handleZoomChange}
                    />
                    <button
                         className="plus-button plus-button--small"
                         onClick={zoomIn}
                    />
               </div>
          </>
     );
});
