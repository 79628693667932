import React, { memo, useEffect, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch } from 'react-redux';
import { HIDE_MAIN_DRAWER, HIDE_DRAWER } from '../../../redux/actions';
import { DrawerWrapper, RegularDrawer } from './Drawer.styles';

const drawerRoot = document.getElementById('drawer');

export const Drawer = memo(({ children }) => {
     const dispatch = useDispatch();

     const hideDrawer = useCallback(
          (e) => {
               const isDrawerBody =
                    e.target !== e.currentTarget && !e.target.dataset.close;

               if (isDrawerBody) return;

               dispatch({ type: HIDE_DRAWER });
          },
          [dispatch]
     );

     return (
          <RegularDrawer onClick={hideDrawer}>
               <div className="drawer-body">
                    <div className="drawer-toolbar">
                         <span
                              role="button"
                              className="close-btn"
                              data-close="close"
                         >
                              <i className="la la-close" data-close="close" />
                         </span>
                    </div>
                    {children}
               </div>
          </RegularDrawer>
     );
});

export const MainDrawer = memo(({ children }) => {
     const dispatch = useDispatch();
     const drawer = document.createElement('div');

     useEffect(() => {
          drawerRoot.appendChild(drawer);

          return () => drawerRoot.removeChild(drawer);
     }, []);

     const hideDrawer = useCallback(
          (e) => {
               const isDrawerBody =
                    e.target !== e.currentTarget && !e.target.dataset.close;

               if (isDrawerBody) return;

               dispatch({ type: HIDE_MAIN_DRAWER });
          },
          [dispatch]
     );

     const Drawer = (
          <DrawerWrapper onClick={hideDrawer}>
               <div className="drawer-body">
                    <div className="drawer-toolbar">
                         <span
                              role="button"
                              className="close-btn"
                              data-close="close"
                         >
                              <i className="la la-close" data-close="close" />
                         </span>
                    </div>
                    {children}
               </div>
          </DrawerWrapper>
     );

     return createPortal(Drawer, drawer);
});
