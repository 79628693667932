import styled from 'styled-components';
import { COLORS, FONT, SIZE } from '../../../constants/style.constants';

export const FilterableTableWrapper = styled.div`
     .custom-table {
          padding: 39px 55px;
     }

     .dropdown {
          box-shadow: none;
     }

     .table-actions {
          margin-bottom: 15px;
          .btn-outline-primary {
               border: 1px solid #e1e4ec;
               border-radius: 4px;
               color: ${COLORS.TEXT_GREY};
               padding: 8px 12px !important;

               &:hover {
                    background-color: ${COLORS.BLUE};
                    border: 1px solid ${COLORS.BLUE};
                    color: ${COLORS.WHITE};
               }
          }
     }

     .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
     .btn-group > .btn-group:not(:last-child) > .btn {
          border-right: none;
     }
     .table-actions,
     .table-content {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          @media only screen and (max-width: 400px) {
               flex-wrap: wrap;
               justify-content: center;
               margin-bottom: 20px;
          }

          .heading {
               color: ${COLORS.DARK_BLUE};
               margin-right: 15px;
               font-weight: ${FONT.MEDIUM};
               font-size: ${SIZE.BIG};
               line-height: 22px;
               /* text-transform: capitalize; */
          }

          .results {
               color: ${COLORS.LAVANDER};
               border-left: 1px solid ${COLORS.LIGHTER_BLUE};
               margin-right: 30px;
               padding-left: 14px;
               font-weight: ${FONT.REGULAR};
               font-size: ${SIZE.NORMAL};
               line-height: 18px;
          }
     }
     .search-box {
          position: relative;

          input {
               color: ${COLORS.TEXT_GREY};
               max-width: 174px;
               max-height: 32px;
               border-radius: 4px;
               border: 1px solid ${COLORS.LIGHTER_BLUE};
               padding: 8px 14px;
               font-size: ${SIZE.SMALL};
               line-height: 17px;
               padding-right: 35px;

               &:focus {
                    color: ${COLORS.GREY};
                    background-color: ${COLORS.WHITE};
                    border-color: ${COLORS.SKY_BLUE};
                    outline: 0 !important;
                    box-shadow: none !important;
               }

               &::placeholder {
                    color: ${COLORS.PLACEHOLDER_GREY};
               }
          }

          span {
               position: absolute;
               top: 0;
               right: 14px;
               min-height: 32px;
               display: flex;
               align-items: center;

               svg {
                    height: 16px;
                    width: 17px;
               }
          }
     }

     .btn {
          padding: 9px 12px !important;
     }

     .table-container {
          border: 1px solid ${COLORS.LIGHTER_BLUE};
          border-radius: 4px !important;
     }

     .table {
          margin: 0 !important;

          .td,
          .th {
               font-size: ${SIZE.SMALL} !important;
          }

          .td {
               vertical-align: middle;
               .data-item {
                    display: flex;
                    align-items: center;
                    img {
                         height: 40px;
                         padding-right: 7px;
                         /* border-radius: 50% !important; */
                    }
               }
          }

          thead {
               tr:hover {
                    background-color: transparent;
               }
          }

          tr,
          thead {
               border-bottom: 1px solid ${COLORS.PALE_BLUE};
          }

          tr {
               &.active {
                    background-color: ${COLORS.PALE_BLUE} !important;
               }
          }

          th {
               padding: 19px 31px;
               font-weight: ${FONT.MEDIUM};
               line-height: 17px;
               color: ${COLORS.DARK_BLUE};
          }

          td {
               padding: 31px;
               line-height: 17px;
               color: ${COLORS.DARK_BLUE};

               &:last-child {
                    padding: 0 0 0 12px;
                    vertical-align: middle;
               }

               &:first-child {
                    font-weight: ${FONT.MEDIUM};
               }
          }

          td,
          th {
               border: none;
          }

          tr:hover {
               color: ${COLORS.DARK_BLUE};
               background-color: rgba(24, 112, 244, 0.05);
          }
     }

     .datatable__pager {
          margin: 0px;
          padding: 30px;
          display: flex;
          align-items: start;
          justify-content: space-between;
          flex-wrap: wrap;

          @media only screen and (max-width: 1082px) {
               flex-direction: column;
               justify-content: center;
               align-items: center;
          }

          .tabel__pager-info {
               display: flex;
               align-items: center;

               @media only screen and (max-width: 1082px) {
                    margin-top: 20px;
               }

               &--text {
                    color: ${COLORS.DARK_BLUE};
                    font-weight: ${FONT.LIGHT};
                    font-size: ${SIZE.SMALL};
                    line-height: 20px;
                    margin-left: 9px;
               }

               .dropdown-toggle {
                    background-color: ${COLORS.PALE_BLUE};
                    border: none;
                    color: ${COLORS.DARKER_GREY};
                    display: flex;
                    align-items: center;
                    padding: 9px 17px !important;

                    svg {
                         margin-left: 14px;
                    }

                    &:after {
                         display: none;
                    }

                    &:hover {
                         color: ${COLORS.WHITE};
                         background-color: ${COLORS.BLUE};
                    }
               }

               .dropdown-menu {
                    &.show {
                         border: none;
                         box-shadow: 0px 0px 20px 0px rgba(82, 63, 105, 0.15) !important;
                    }
               }

               .dropdown-item {
                    color: ${COLORS.DARK_BLUE};
                    font-size: ${SIZE.NORMAL};
                    white-space: nowrap;
                    line-height: 1.5;

                    &:hover,
                    &:active {
                         background-color: ${COLORS.PALE_BLUE};
                    }
               }
          }

          .pagination {
               margin-bottom: 0;

               .page-item {
                    margin: 0 5px;
                    &.active {
                         .page-link,
                         span {
                              color: ${COLORS.WHITE};

                              &:hover,
                              &:active,
                              &:focus {
                                   background-color: ${COLORS.BLUISH};
                                   color: ${COLORS.WHITE};
                                   outline: 0 !important;
                                   box-shadow: none;
                                   transition: all 0.15s ease;
                              }
                         }
                    }
                    .page-link {
                         display: flex;
                         align-items: center;
                         justify-content: center;
                         border: none;
                         height: 29px;
                         width: 29px;
                         /* height: 2.25rem;
                         min-width: 2.25rem; */
                         padding: 9px;
                         border-radius: 3px;
                         font-size: ${SIZE.NORMAL};
                         font-weight: ${FONT.MEDIUM};
                         color: ${COLORS.DARKER_GREY};

                         &:hover,
                         &:active {
                              background-color: ${COLORS.BLUISH};
                              color: ${COLORS.WHITE};
                         }

                         &:active,
                         &:focus {
                              box-shadow: none;
                              outline: 0 !important;
                         }
                    }

                    &.disabled {
                         color: ${COLORS.DARKER_GREY};
                         background-color: ${COLORS.PALE_BLUE};
                         opacity: 0.3;
                    }
               }
          }
     }

     @media only screen and (max-width: 1023px) {
          .td,
          .th,
          .table,
          .heading,
          .results {
               font-size: ${SIZE.TINY} !important;
          }

          .table {
               table-layout: auto !important;
               width: 100% !important;
          }

          .td,
          .th {
               padding: 15px !important;
          }

          .table-actions {
               flex-direction: column;
          }
     }

     @media only screen and (max-width: 1320px) {
          .table-container {
               overflow: auto;
          }
     }
`;

export default FilterableTableWrapper;
