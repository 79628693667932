import { createBrowserHistory } from 'history';

/**
 * Language flags
 */
import FLAG_EN from '../assets/images/flag-usa.svg';
import FLAG_LT from '../assets/images/flag-ltu.svg';

export const LANGUAGE_FLAGS = {
     'en': FLAG_EN,
     'lt': FLAG_LT
};

export const HISTORY = createBrowserHistory();
