/**
 * COLORS
 */
export const COLORS = {
     WHITE: '#FFF',
     TEXT_GREY: '#606D80',
     LIGHT_GREY: '#F5F5F5',
     BORDER_GREY: '#DEE1EB',
     SKIN_LIGHT: '#F9FAFE',
     PALE_BLUE: '#F7F8FA',
     BLUE: '#1870F4',
     BLUISH: '#0e6cf7',
     SAPPHIRE_BLUE: '#075bd8',
     DARK_BLUE: '#001737',
     LIGHTER_BLUE: '#E1E4EC',
     LIGHT_BLUE: '#f2f3f7',
     PLACEHOLDER_GREY: '#8492A5',
     GREEN: '#2DB65D',
     BOOTSTRAP_GREEN: '#2EAC58',
     LIGHT_GREEN: '#EAF7EE',
     DARKER_GREY: '#8493a5',
     DARKER_GREEN: '#25974d',
     BORDER_GREEN: '#238d48',
     LAVANDER: '#959CB6',
     RED: '#DB4A58',
     BOOTSTRAP_BLUE: '#0069d9',
     BOOTSTRAP_BORDER_BLUE: '#0062cc',
     SKY_BLUE: '#4990f9',
     GREY: '#495057',
     LINKS_GREY: '#A9A7BC',
     SILVER: '#d9dde7',
     WHITE_SMOKE: '#f4f5f8',
     FLAT_GREY: '#dee1eb',
     BLUISH_WHITE: '#e2e5ed',
     EGYPTIAN_BLUE: '#0756cb',
     LIGHT_RED: '#FBEDEE',
     YELLOW: '#fdc04d',
     DARKER_YELLOW: '#ffb822',
     LIGHTER_YELLOW: '#fff8e9',
     BRAND_HIGHLIGHT: '#e3eefe',
     FORM_BUILDER_WARNING: '#FFF8EA',
     RANGE_SLIDER_THUMB: '#B8BFCC'
};

export const THEME = {
     DARK: '#1F1E2E',
     DARKER: '#181824',
};

/**
 * FONTS
 */
export const FONT = {
     LIGHT: '300',
     REGULAR: '400',
     MEDIUM: '500',
     BOLD: '700',
};

/**
 * FONT-sizes
 */
export const SIZE = {
     MICRO: '11px',
     TINY: '12px',
     SMALL: '13px',
     NORMAL: '14px',
     INCREASED: '15px',
     LARGER: '16px',
     LARGE: '16.8px',
     BIG: '17px',
     HUGE: '18px',
     HUGER: '18.2px',
     GREAT: '20px',
     ENORMOUS: '24px',
     GIGANTIC: '28px',
};
/**
 * PADDING
 */
export const CONTAINER_PADDING = `
     padding-left: 60px;
     padding-right: 60px;
`;

export const FLEX_ROW_CENTER = `
     display: flex;
     justify-content: space-between;
     align-items: center;
`;

export const FORM_TEXT = `
     font-weight: ${FONT.REGULAR};
     font-size: ${SIZE.NORMAL};
     line-height: 18px;
`;

export const FORM_PLACEHOLDER = `
     font-weight: ${FONT.REGULAR};
     font-size: ${SIZE.NORMAL};
`;

/**
 * TRANSITION
 */
export const TRANSITION_MODE = 'out-in';

/**
 * GENERAL
 */
export const BORDER_THEMED = `
     border: 1px solid ${COLORS.LIGHTER_BLUE};
`;
