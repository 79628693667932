import styled from 'styled-components';
import { Dropdown } from 'react-bootstrap';
import { COLORS, FONT, SIZE } from '../../../constants/style.constants';

export const StyledDropdown = styled(Dropdown)`
     border-radius: 4px;
     margin-left: 5px;

     .dropdown-toggle::after {
          display: none;
     }

     .btn {
          border: none;
          transition: all 0.15s ease;
          background-color: ${COLORS.BRAND_HIGHLIGHT};
          padding: 10px;
          min-width: 0;

          &:hover {
               background-color: ${COLORS.BLUE};
          }

          .icon {
               height: 17px;
          }
     }

     .dropdown-menu {
          margin-top: 9px;
          border: none;
          box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
          border-radius: 4px;
          padding-top: 29px;
          padding-bottom: 29px;

          &.show {
               animation: dropdown-menu-fade-in 0.3s ease 1,
                    dropdown-menu-move-up 0.3s ease-out 1;
               animation-duration: 0.3s, 0.3s;
               animation-timing-function: ease, ease-out;
               animation-delay: 0s, 0s;
               animation-iteration-count: 1, 1;
               animation-direction: normal, normal;
               animation-fill-mode: none, none;
               animation-play-state: running, running;
               animation-name: dropdown-menu-fade-in, dropdown-menu-move-up;
          }

          .dropdown-item {
               .language-select-dropdown-text {
                    line-height: 18px;
                    font-size: ${SIZE.NORMAL};
                    color: ${COLORS.DARK_BLUE};
                    padding-left: 12px;
               }

               &:hover,
               &:active {
                    background-color: ${COLORS.LIGHT_GREY};
               }

               &:hover {
                    .language-select-dropdown-text {
                         color: ${COLORS.BLUE} !important;
                    }
               }

               img {
                    border-radius: 50px;
               }
          }
     }

     @keyframes dropdown-menu-fade-in {
          from {
               opacity: 0;
          }
          to {
               opacity: 1;
          }
     }

     @keyframes dropdown-menu-move-up {
          from {
               margin-top: 10px;
          }
          to {
               margin-top: 0;
          }
     }
`;

export const UserOptionsWrapper = styled(StyledDropdown)`
     img {
          min-height: 37px;
          min-width: 37px;
          border-radius: 3px;
     }

     .dropdown-menu {
          padding: 0;
          min-width: 380px;
     }

     .dropdown {
          max-width: 100%;
          background-color: ${COLORS.LIGHTER_BLUE};
     }

     .btn {
          padding: 0;
          border-radius: 4px;
     }

     .dropdown-header {
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          background-size: cover;
          background-repeat: no-repeat;
          padding: 28px 21px;
          background-color: ${COLORS.SKIN_LIGHT};

          img {
               margin-right: 16px;
               height: 60px;
               width: 60px;
          }
          .text {
               color: ${COLORS.DARK_BLUE};
               font-size: ${SIZE.HUGER};
               font-weight: ${FONT.MEDIUM};
          }
     }

     .las {
          font-size: ${SIZE.SMALL};
          line-height: 0;
     }

     .settings-item {
          display: flex;
          align-items: center;
          padding: 16px 21px;
          cursor: pointer;

          .user-icon {
               margin-right: 18px;
          }

          .item-content {
               flex-grow: 1;

               .item-title {
                    color: ${COLORS.DARK_BLUE};
                    font-size: ${SIZE.NORMAL};
                    font-weight: ${FONT.MEDIUM};
               }

               .item-text {
                    color: ${COLORS.TEXT_GREY};
                    font-size: ${SIZE.NORMAL};
                    font-weight: ${FONT.LIGHT};
               }
          }
     }

     .settings-item:hover {
          background-color: ${COLORS.SKIN_LIGHT};

          .item-title {
               color: ${COLORS.BLUE};
          }
     }

     .card-footer {
          background-color: ${COLORS.WHITE};
          border-top: 2px solid ${COLORS.PALE_BLUE};
          padding: 22px;
     }

     .btn-outline-primary {
          font-size: ${SIZE.MICRO};
          line-height: 1.5;
          border-radius: 3px;
          padding: 7px 14px;
     }
`;

export const LanguageSelectWrapper = styled(StyledDropdown)`
     .active {
          background-color: ${COLORS.LIGHT_GREY};
          width: 100%;
     }

     img {
          height: 18px;
          width: 18px;
     }
`;

export const NavigationalDropdownWrapper = styled(StyledDropdown)`
     .show > .btn-primary.btn-themed-blue,
     .show > .btn-primary.btn-themed-blue:focus,
     .btn-primary:not(:disabled):not(.disabled):active:focus,
     .btn-primary:not(:disabled):not(.disabled).active:focus {
          background-color: rgba(24, 112, 244, 0.12);
          color: #1870f4;
          border: 1px solid transparent;
     }

     .show > .btn-primary.btn-inactive,
     .show > .btn-primary.btn-inactive:focus,
     .btn-primary:not(:disabled):not(.disabled):active:focus,
     .btn-primary:not(:disabled):not(.disabled).active:focus {
          background-color: #f2f3f8;
          color: ${COLORS.DARK_BLUE};
          border: 1px solid transparent;
     }

     .btn {
          padding: 12px 25px;

          &.btn-inactive {
               background-color: transparent;
               border: 1px solid transparent !important;
               color: ${COLORS.DARK_BLUE};
               font-weight: ${FONT.MEDIUM};
               font-size: ${SIZE.NORMAL};
               line-height: 18px;
               margin-top: 15px;
               margin-bottom: 15px;
               padding: 12px 25px;

               &:active,
               &:hover {
                    background-color: #f2f3f8 !important;
                    border: 1px solid transparent !important;
                    color: ${COLORS.DARK_BLUE} !important;
                    font-weight: ${FONT.MEDIUM};
                    margin-top: 15px;
                    margin-bottom: 15px;
               }
          }
     }

     .dropdown-menu {
          padding-top: 20px;
          padding-bottom: 20px;
     }
     .dropdown-item {
          display: flex;
          align-items: center;
          position: relative;
          cursor: pointer;
          min-width: 275px;

          .kt-svg-icon {
               margin-right: 15px;
               /* background-color: transparent; */
          }
          .text {
               line-height: 18px;
               font-size: ${SIZE.NORMAL};
               color: ${COLORS.DARK_BLUE};
               padding: 14px 0;
          }
          &:hover,
          &:active {
               background-color: ${COLORS.LIGHT_GREY};

               .kt-svg-icon g [fill] {
                    fill: ${COLORS.BLUE};
                    opacity: 0.8;
               }
          }

          &:hover {
               .text {
                    color: ${COLORS.BLUE} !important;
               }
          }
     }

     .dropdown-sub-menu {
          position: absolute;
          border-radius: 4px;
          padding-top: 29px;
          padding-bottom: 29px;
          box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
          right: -275px;
          top: 0%;
          background-color: ${COLORS.WHITE};
          min-width: 275px;

          animation: dropdown-menu-fade-in 0.3s ease 1,
               dropdown-menu-move-down 0.3s ease-out 1;
          animation-duration: 0.3s, 0.3s;
          animation-timing-function: ease, ease-out;
          animation-delay: 0s, 0s;
          animation-iteration-count: 1, 1;
          animation-direction: normal, normal;
          animation-fill-mode: none, none;
          animation-play-state: running, running;
          animation-name: dropdown-menu-fade-in, dropdown-menu-move-down;

          .kt-svg-icon-sub {
               margin-right: 15px;
          }

          a {
               text-decoration: none;
          }

          .submenu-item {
               line-height: 18px;
               font-size: ${SIZE.NORMAL};
               color: ${COLORS.DARK_BLUE};
               padding: 14px 29px;
               margin: auto;

               &:hover,
               &:active {
                    background-color: ${COLORS.LIGHT_GREY};
                    .kt-svg-icon-sub g [fill] {
                         fill: ${COLORS.BLUE};
                         opacity: 1;
                    }
               }

               &:hover {
                    color: ${COLORS.BLUE} !important;
               }
          }
     }

     @keyframes dropdown-menu-move-down {
          from {
               margin-top: -10px;
          }
          to {
               margin-top: 0;
          }
     }
`;

export const TableDropdownWrapper = styled(Dropdown)`
     .dropdown {
          margin: 0 !important;
          box-shadow: none;
     }
     .dropdown-toggle {
          &.btn-default {
               border: none;
               margin: 0 !important;

               &:hover,
               &.active {
                    transition: color 0.3s ease;
                    background: transparent;
                    color: ${COLORS.BLUE};
                    cursor: pointer;
                    text-decoration: none;
                    .sidebar-widget__section {
                         color: ${COLORS.BLUE};
                    }

                    .kt-svg-icon g [fill] {
                         fill: ${COLORS.BLUE};
                    }
               }
          }
     }
     .dropdown-toggle {
          &.btn-outline-primary {
               border: 1px solid #e1e4ec;
               border-radius: 4px;
               color: ${COLORS.TEXT_GREY};
               padding: 5px 10px;
               background-color: ${COLORS.WHITE};

               &:hover {
                    color: ${COLORS.WHITE};
                    border: 1px solid ${COLORS.BLUE};
                    background-color: ${COLORS.BLUE};
               }

               svg {
                    margin-left: 13px;
               }
          }
     }

     .dropdown-menu {
          &.show {
               top: -10px !important;
          }
     }

     .dropdown-item {
          display: flex;
          align-items: center;
          cursor: pointer;

          span {
               padding-left: 13px;
               font-weight: ${FONT.REGULAR};
               font-size: ${SIZE.NORMAL};
               /* line-height: 18px; */
               color: ${COLORS.DARK_BLUE};

               &.remove {
                    color: ${COLORS.RED} !important;
               }
          }
          svg {
               height: 18px;
               width: 16px;
          }
     }
`;
