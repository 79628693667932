import * as ACTIONS from '../actions';

export const INITIAL_STATE = {
     mainDrawerShown: false,
     drawerShown: false,
     toolbarShown: false,
     collapseContainerShown: false,
};

export default function uiReducer(state = INITIAL_STATE, action) {
     switch (action.type) {
          case ACTIONS.SHOW_MAIN_DRAWER:
               return { ...state, mainDrawerShown: true };
          case ACTIONS.HIDE_MAIN_DRAWER:
               return { ...state, mainDrawerShown: false };
          case ACTIONS.SHOW_TOOLBAR:
               return { ...state, toolbarShown: true };
          case ACTIONS.HIDE_TOOLBAR:
               return { ...state, toolbarShown: false };
          case ACTIONS.SHOW_DRAWER:
               return { ...state, drawerShown: true };
          case ACTIONS.HIDE_DRAWER:
               return { ...state, drawerShown: false };
          default:
               return state;
     }
}
