import React from 'react';

export const CustomToggle = React.forwardRef(({ children, onClick, isWarning = false }, ref) => (
     <a
          href="#"
          ref={ref}
          onClick={(e) => {
               e.preventDefault();
               onClick(e);
          }}
          className={`select-dropdown-toggle form-control ${isWarning ? 'is-warning' : ''}`}
     >
          {children}
     </a>
));
