import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const ProtectedRoute = ({ children, ...rest }) => {
     return (
          <Route
               {...rest}
               render={({ location }) =>
                    localStorage.getItem('token') ? (
                         children
                    ) : (
                         <Redirect
                              to={{
                                   pathname: '/auth/login',
                                   state: { from: location },
                              }}
                         />
                    )
               }
          />
     );
};

export const AdminRoute = ({ children, isAdmin, ...rest }) => {
     if (isAdmin === null || isAdmin === false) {
          return <></>;
     }

     return <Route {...rest}>{children}</Route>;
};
