import React, { memo, useCallback, useState, lazy } from 'react';
import { useDispatch } from 'react-redux';
import { HIDE_MAIN_DRAWER, SHOW_MAIN_DRAWER } from '../../../redux/actions';
import ChevronRightIcon from '../../../assets/icons/metronic/chevron-right-icon';
import {
     ListContainer,
     CollapsibleListWrapper,
} from './CollapsibleList.styles';

const CollapseContainer = lazy(() => import('./CollapseContainer'));

export const ListItem = memo(({ children, ...props }) => {
     const dispatch = useDispatch();

     const handleClose = useCallback(() => {
          dispatch({ type: HIDE_MAIN_DRAWER });
     }, [dispatch]);

     const { subItem } = props;

     return (
          <ListContainer onClick={handleClose} {...props}>
               <CollapsibleListWrapper
                    role="button"
                    style={{ paddingLeft: subItem ? 60 : 'inheit' }}
               >
                    {children}
               </CollapsibleListWrapper>
          </ListContainer>
     );
});

export const CollapsibleList = memo(
     ({
          children,
          listHeader = null,
          listContent = null,
          expanded = false,
          close = false,
          ...props
     }) => {
          const dispatch = useDispatch();
          const [collapse, setCollapse] = useState(expanded);

          const handleClose = useCallback(
               (e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    if (close) {
                         return dispatch({ type: HIDE_MAIN_DRAWER });
                    }

                    setCollapse(!collapse);
               },
               [close, collapse]
          );

          return (
               <div onClick={handleClose} {...props}>
                    <CollapsibleListWrapper role="button" close={close}>
                         <div className="wrapper">
                              {close ? children : listHeader}
                              <ChevronRightIcon
                                   className={collapse ? 'down' : ''}
                              />
                         </div>
                    </CollapsibleListWrapper>
                    <CollapseContainer collapse={collapse}>
                         {listContent}
                    </CollapseContainer>
               </div>
          );
     }
);
