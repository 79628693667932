import React from 'react';

function EditIcon() {
     return (
          <svg
               xmlns="http://www.w3.org/2000/svg"
               width="18"
               height="18"
               fill="none"
               viewBox="0 0 18 18"
          >
               <path
                    fill="#606D80"
                    d="M16.613 11.307c-.247 0-.448.201-.448.449v3.98c0 .743-.602 1.344-1.345 1.345H2.241a1.346 1.346 0 01-1.344-1.345V4.054c0-.742.602-1.344 1.344-1.345h3.98a.448.448 0 100-.896h-3.98A2.244 2.244 0 000 4.054v11.682a2.244 2.244 0 002.241 2.242h12.58a2.244 2.244 0 002.24-2.242v-3.98c0-.248-.2-.449-.448-.449z"
               ></path>
               <path
                    fill="#606D80"
                    d="M16.884.66a2.017 2.017 0 00-2.853 0L6.033 8.656a.448.448 0 00-.115.197l-1.052 3.797a.448.448 0 00.552.552l3.797-1.052a.449.449 0 00.197-.115l7.998-7.998a2.02 2.02 0 000-2.853L16.884.66zM7.01 8.947l6.545-6.545 2.111 2.11L9.12 11.06 7.01 8.95zm-.422.846l1.686 1.687-2.332.646.646-2.333zm10.188-6.39l-.476.476-2.11-2.112.475-.475a1.12 1.12 0 011.585 0l.526.526a1.122 1.122 0 010 1.585z"
               ></path>
          </svg>
     );
}

export default EditIcon;
