/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import PencilIcon from '../../../assets/icons/metronic/pencil-icon';
import { COLORS } from '../../../constants/style.constants';
import { ImageCropperModal } from './ImageCropperModal';
import { StyledPictureUploader } from './PictureUploader.style';
import {AvatarLoader} from "../loader/AvatarLoader";
import {ImagePreviewModal} from "./ImagePreviewModal";
import {convertBytesToMb, isValidFileSize} from "../../../utils/file.utils";
import toastr from 'toastr';
import {convertMimesToExtensions, isValidMimeType} from "../../../utils/mime.utils";

const TimesIcon = () => (
     <svg
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          className="svg-inline--fa fa-times fa-w-11"
          data-icon="times"
          data-prefix="fas"
          viewBox="0 0 352 512"
     >
          <path
               fill="currentColor"
               d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
          ></path>
     </svg>
);

export const PictureUploader = ({
     picture,
     preview,
     getFile,
     getDefault,
     isLoading,
     isDefault,
     handleRemovePicture = null,
     handleRemovePreview = null,
     infoText = false,
     required = false,
     fullPreview = null,
     fullPreviewName = null,
     accept = null,
     fileLimit = null
}) => {
     const { t } = useTranslation();

     const previewRef = useRef();
     const inputRef = useRef();
     const [url, setUrl] = useState(null);
     const [newUrl, setNewUrl] = useState(null);
     const [showModal, setShowModal] = useState(false);
     const [showPreviewModal, setShowPreviewModal] = useState(false);
     const [startUpload, setStartUpload] = useState(false);

     const [uploadedFile, setUploadedFile] = useState(false);

     const [pictureUploaded, setPictureUploaded] = useState(false);

     const [hovered, setHovered] = useState(false);
     const [closeHovered, setCloseHovered] = useState(false);

     useEffect(() => {
          return function cleanup() {
               setUrl(null);
               setNewUrl(null);
               setShowModal(false);
               setShowPreviewModal(false);
               setStartUpload(false);
               setUploadedFile(false);
               setPictureUploaded(false);
               setHovered(false);
               setCloseHovered(false);
          };
     }, []);

     useEffect(() => {
          if (pictureUploaded && url) {
               setShowModal(true);
          }
     }, [pictureUploaded, url]);

     useEffect(() => {
          if (preview) {
               setUrl(preview);
          } else if (picture) {
               setUrl(picture);
          }
     }, [picture, preview]);

     useEffect(() => {
          if (startUpload === true) {
               inputRef.current.click();
               setStartUpload(false);
          }
     }, [startUpload]);

     const handleCloseModal = useCallback(() => {
          setShowModal(false);
     }, [showModal, pictureUploaded]);

     const handleClosePreviewImage = () => {
          setShowPreviewModal(false);
     };

     const openPreviewImage =  () => {
          setShowPreviewModal(true);
     };

     const handleHover = useCallback(() => {
          setHovered(!hovered);
     }, [hovered]);

     const uploadPreview = useCallback(() => {
          const imgBox = previewRef.current;
          const file = inputRef.current.files[0];
          const isValidSize = fileLimit ? isValidFileSize(file, fileLimit) : true;
          const isValidExtension = accept ? isValidMimeType(file, accept) : true;

          if (isValidSize && isValidExtension) {
               const reader = new FileReader();
               reader.onloadend = () => setNewUrl(reader.result);

               if (file) {
                    reader.readAsDataURL(file);

                    previewRef.current = imgBox;

                    setUploadedFile(file);
                    setPictureUploaded(true);
                    setShowModal(true);
               } else {
                    imgBox.style.backgroundImage = `url(${picture})`;
                    previewRef.current = imgBox;
               }
          } else {
               if (!isValidSize) {
                    toastr.error(t('picture_uploader.file_size_limit_hit', {limit: convertBytesToMb(fileLimit)}));
               }
               if (!isValidExtension) {
                    toastr.error(t('picture_uploader.file_invalid_mime', {
                         mimes: convertMimesToExtensions(accept)
                    }));
               }
          }

          inputRef.current.value = null;
     }, [getFile, picture]);

     const removePicture = () => {
          const imgBox = previewRef.current;
          const defaultUrl = getDefault();

          if (handleRemovePicture) {
               handleRemovePicture();

               return setPictureUploaded(false);
          }

          imgBox.style.backgroundImage = `url(${defaultUrl})`;
          previewRef.current = imgBox;
          setPictureUploaded(false);
     };

     const removePreview = () => {
          const imgBox = previewRef.current;
          const defaultUrl = getDefault();

          if (handleRemovePreview) {
               handleRemovePreview();

               return setPictureUploaded(false);
          }

          imgBox.style.backgroundImage = `url(${defaultUrl})`;
          previewRef.current = imgBox;
          setPictureUploaded(false);
     };

     const handleCroppedFile = (crop) => {
               // setUrl(dataUrl);
               setPictureUploaded(false);
               getFile(uploadedFile, crop);
          };

     const startUploadProcess = () => {
          setStartUpload(true);
     };

     return (
          <StyledPictureUploader
               style={{ paddingBottom: infoText && infoText === ' ' && 0 }}
          >
               <ImageCropperModal
                    show={showModal}
                    url={newUrl}
                    picture={picture}
                    onCrop={handleCroppedFile}
                    handleClose={handleCloseModal}
                    setShowModal={setShowModal}
               />
               <ImagePreviewModal
                   show={showPreviewModal}
                   media={fullPreview}
                   name={fullPreviewName}
                   handleClose={handleClosePreviewImage}
                   handleUpload={() => {
                        handleClosePreviewImage();
                        startUploadProcess();
                   }}
               />
               <div className="avatar avatar--outline" id="user_avatar">
                    <div
                         ref={previewRef}
                         className="avatar__holder"
                    >
                         {isLoading && (
                             <AvatarLoader width={120} height={120} />
                         )}
                         {!isLoading && (
                             <img src={url} width={120} height={120} />
                         )}
                    </div>
                    {!isLoading && (
                        <label
                            className="avatar__upload"
                            onMouseEnter={handleHover}
                            onMouseLeave={handleHover}
                        >
                             <PencilIcon
                                 color={COLORS.BLUE}
                                 activeColor={COLORS.WHITE}
                                 hovered={hovered}
                             />
                             <input
                                 required={required}
                                 ref={inputRef}
                                 type="file"
                                 name="profile_avatar"
                                 accept={accept}
                                 onClick={(e) => {
                                      if ((fullPreview && !startUpload) || (!isDefault && !startUpload)) {
                                           e.preventDefault();
                                           openPreviewImage();
                                      }
                                 }}
                                 onChange={uploadPreview}
                             />
                        </label>
                    )}
                    {preview && !isLoading && (
                        <span
                            className="avatar__cancel"
                            onClick={() => removePreview()}
                        >
                              <TimesIcon
                                  color={COLORS.BLUE}
                                  activeColor={COLORS.WHITE}
                                  hovered={closeHovered}
                              />
                         </span>
                    )}
                    {!isDefault && !isLoading && !preview && (
                         <span
                              className="avatar__cancel"
                              onClick={() => removePicture()}
                         >
                              <TimesIcon
                                   color={COLORS.BLUE}
                                   activeColor={COLORS.WHITE}
                                   hovered={closeHovered}
                              />
                         </span>
                    )}
                    <div className="recommended-size">
                         {infoText || t('picture_uploader.recommended_size')}
                    </div>
               </div>
          </StyledPictureUploader>
     );
};
