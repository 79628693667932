import styled from 'styled-components';
import { Alert } from 'react-bootstrap';
import { COLORS, FONT, SIZE } from '../../../constants/style.constants';

export const StyledAlert = styled(Alert)`
     text-align: ${({ textCenter }) => (textCenter ? 'center' : 'start')};
     font-weight: ${FONT.MEDIUM};
     font-size: ${SIZE.NORMAL};
     line-height: 21px;
     padding: 14px 28px;
     margin-bottom: 20px;
     border: none;

     &.alert-dismissible {
          opacity: 0.8;
          animation: show 0.75s ease;

          .close {
               font-size: ${SIZE.ENORMOUS};
               font-weight: ${FONT.MEDIUM};
               line-height: 0 !important;
               text-shadow: 0 1px 0 #fff;
               height: 46px;

               &:hover {
                    opacity: 1;
                    transition: opacity 0.3 ease;
               }

               span {
                    padding: 0;
               }
          }

          @keyframes show {
               from {
                    opacity: 0;
               }
               to {
                    opacity: 0.8;
               }
          }
     }

     &.alert-primary {
          color: ${COLORS.BLUE} !important;
          background-color: ${COLORS.BRAND_HIGHLIGHT};
     }

     &.alert-success {
          color: ${COLORS.GREEN} !important;

          .close {
               color: ${COLORS.GREEN};
          }
     }

     &.alert-danger {
          color: ${COLORS.RED} !important;

          .close {
               color: ${COLORS.RED};
          }
     }

     @media only screen and (max-width: 1023px) {
          font-size: ${SIZE.TINY};
     }
`;
