import styled from 'styled-components';
import { COLORS } from '../../../constants/style.constants';

export const LoadingBarWrapper = styled.div`
     position: absolute;
     bottom: -3px;
     width: ${({ width }) => `${width}%`};
     height: 3px;
     opacity: 1;
     background-color: ${COLORS.BLUE};
     transition: width 1s ease-in-out;
     transition: opacity 0.3s ease-in;
`;
