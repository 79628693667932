import styled from 'styled-components';
import { COLORS } from '../../../constants/style.constants';

export const MeatballMenuWrapper = styled('span')`
     margin-left: 10px;
     padding: 10px;
     position: relative;
     display: flex;

     border: none;
     margin: 0 !important;

     transition: all 0.15s ease;

     &:hover,
     &.active {
          background: transparent;
          color: ${COLORS.BLUE};
          cursor: pointer;
          text-decoration: none;
          .sidebar-widget__section {
               color: ${COLORS.BLUE};
          }

          .kt-svg-icon g [fill] {
               fill: ${COLORS.BLUE};
          }
     }

     @media only screen and (min-width: 1024px) {
          display: none;
     }
`;
