import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SHOW_MAIN_DRAWER, HIDE_MAIN_DRAWER } from '../../../redux/actions';
import { StyledMobileToggler } from './MobileToggler.styles';

export const MobileToggler = memo(() => {
     const drawerShown = useSelector((state) => state.ui.mainDrawerShown);
     const dispatch = useDispatch();

     const showDrawer = useCallback(
          () => dispatch({ type: SHOW_MAIN_DRAWER }),
          [dispatch]
     );

     const hideDrawer = useCallback(
          () => dispatch({ type: HIDE_MAIN_DRAWER }),
          [dispatch]
     );

     const toggleDrawer = useCallback(() => {
          if (drawerShown) {
               return hideDrawer();
          }

          showDrawer();
     }, [drawerShown]);

     return <StyledMobileToggler onClick={toggleDrawer} active={drawerShown} />;
});
