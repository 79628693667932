import React from "react";
import { SortingStyle } from './Sorting.style';

export const Sorting = ({field, activeField, direction, onSort}) => {

    let downClassName = 'fas fa-long-arrow-alt-down';
    let upClassName = 'fas fa-long-arrow-alt-up';

    if (activeField === field && direction === 'asc') {
        downClassName += ' active';
    }

    if (activeField === field && direction === 'desc') {
        upClassName += ' active';
    }

    const newDirection = direction === 'asc' ? 'desc' : 'asc';

    return (
        <SortingStyle onClick={() => {onSort(field, newDirection)}}>
            <i className={downClassName} />
            <i className={upClassName} />
        </SortingStyle>
    );
};
