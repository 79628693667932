import React from 'react';

function HomeIcon() {
     return (
       <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none">
         <path fill="#8492A5" d="M10.673 10.233H3.139v1.256h7.534v-1.256z"></path>
         <path
           fill="#8492A5"
           d="M6.906 0L0 5.525V14h13.812V5.525L6.906 0zm5.65 12.744h-11.3V6.152l5.65-4.52 5.65 4.52v6.592z"
         ></path>
       </svg>
     );
   }
   
   export default HomeIcon;