import React from "react"
import ContentLoader from "react-content-loader"

export const ImagePreviewLoader = ({ width = 640, height = 640, ...restProps }) => (
    <ContentLoader
        speed={2}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...restProps}
    >
        <rect x="0" y="0" rx="10" ry="10" width={width} height={height} />
    </ContentLoader>
);
