import React from 'react';
import { useTransition } from '../../../hooks';
import {LoginForm} from "./LoginForm";
import {ForgotPasswordForm} from "./ForgotPasswordForm";

export const LoginBlockForm = () => {

     const { transition, toggleTransition } = useTransition();

     return (
          <div className="transition-group-wrapper">
               <div className={transition ? 'from-hidden' : 'from-visible'}>
                    <LoginForm  toggleTransition={toggleTransition} forgotPasswordLink />
               </div>
               <div className={transition ? 'to-visible' : 'to-hidden'}>
                    <ForgotPasswordForm toggleTransition={toggleTransition} />
               </div>
          </div>
     );
};
