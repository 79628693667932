import styled from 'styled-components';
import { COLORS } from '../../../constants/style.constants';

export const StyledMobileToggler = styled('span')`
     visibility: hidden;
     cursor: pointer;
     position: relative;
     height: 38px;
     width: 38px;
     transition: all 0.15s ease;

     @media only screen and (max-width: 1023px) {
          visibility: visible;

          &:before {
               content: '';
               position: absolute;
               left: 7px;
               top: 12px;
               width: 24px;
               height: 3px;
               margin-bottom: 3px;
               background-color: ${({ active }) =>
                    active ? `${COLORS.BLUISH}` : '#d1d5e2'};
               box-shadow: ${({ active }) =>
                    active
                         ? `0 0.4em 0 0 ${COLORS.BLUISH}, 0 0.8em 0 0 ${COLORS.BLUISH}`
                         : '0 0.4em 0 0 #d1d5e2, 0 0.8em 0 0 #d1d5e2'};
          }

          &:hover:before {
               background-color: ${({ active }) =>
                    active ? `${COLORS.BLUISH}` : '#d1d5e2'};
               box-shadow: ${({ active }) =>
                    active
                         ? `0 0.4em 0 0 ${COLORS.BLUISH}, 0 0.8em 0 0 ${COLORS.BLUISH}`
                         : '0 0.4em 0 0 #d1d5e2, 0 0.8em 0 0 #d1d5e2'};
          }
     }
`;
