import styled from 'styled-components';
import { COLORS } from '../../../constants/style.constants';

export const SortingStyle = styled('div')`
    display: inline-block;
    margin-left: 10px;
    cursor: pointer;
    
    i {
        width: 5px;
        font-size: 12px;
        color: ${COLORS.DARKER_GREY};
    }
    
    i.active {
        color: ${COLORS.DARK_BLUE};
    }
`;
