import * as ACTIONS from '../actions';

export const requestLogin = (payload) => ({
     type: ACTIONS.LOGIN_REQUEST,
     payload,
});

export const successLogin = (token) => {
     localStorage.removeItem('token');
     localStorage.setItem('token', token);

     return { type: ACTIONS.LOGIN_SUCCESS };
};

export const failLogin = (error) => ({
     type: ACTIONS.LOGIN_FAILURE,
     error,
});

export const requestLogout = () => ({
     type: ACTIONS.LOGOUT_REQUEST,
});

export const successLogout = () => {
     localStorage.removeItem('token');

     return {
          type: ACTIONS.LOGOUT_SUCCESS,
     };
};

export const failLogout = (error) => ({
     type: ACTIONS.LOGOUT_FAILURE,
     error,
});

export const requestSignup = (payload) => ({
     type: ACTIONS.SIGNUP_REQUEST,
     payload,
});

export const successSignup = () => {
     return {
          type: ACTIONS.SIGNUP_SUCCESS,
     };
};

export const failSignup = (error) => ({
     type: ACTIONS.SIGNUP_FAILURE,
     error,
});

export const requestForgottenPassword = (payload) => ({
     type: ACTIONS.FORGOTTEN_PASSWORD_REQUEST,
     payload,
});

export const successForgottenPassword = (message) => ({
     type: ACTIONS.FORGOTTEN_PASSWORD_SUCCESS,
     message,
});

export const failForgottenPassword = (error) => ({
     type: ACTIONS.FORGOTTEN_PASSWORD_FAILURE,
     error,
});

export const requestResetPassword = (payload) => ({
     type: ACTIONS.RESET_PASSWORD_REQUEST,
     payload,
});

export const successResetPassword = (payload) => {
     return { type: ACTIONS.RESET_PASSWORD_SUCCESS, payload };
};

export const failResetPassword = (error) => ({
     type: ACTIONS.RESET_PASSWORD_FAILURE,
     error,
});

// TODO: check usage of this
export const clearLogin = () => ({ type: ACTIONS.CLEAR_LOGIN });

export const INITIAL_STATE = {
     loginRequested: false,
     loginSucceeded: false,
     loginFailed: false,
     logoutRequested: false,
     logoutSucceeded: false,
     logoutFailed: false,
     signupRequested: false,
     signupSucceeded: false,
     signupFailed: false,
     forgottenPasswordRequested: false,
     forgottenPasswordSucceeded: false,
     forgottenPasswordFailed: false,
     resetPasswordRequested: false,
     resetPasswordSucceeded: false,
     resetPasswordFailed: false,
     fetchUserAvatarAfterRegistration: false,
     error: null,
     message: null,
};

export default function authReducer(state = INITIAL_STATE, action) {
     switch (action.type) {
          case ACTIONS.LOGIN_REQUEST:
               return {
                    loginRequested: true,
                    loginSucceeded: false,
                    loginFailed: false,
               };
          case ACTIONS.LOGIN_SUCCESS:
               return {
                    loginRequested: false,
                    loginSucceeded: true,
                    loginFailed: false,
               };
          case ACTIONS.LOGIN_FAILURE:
               return {
                    loginRequested: false,
                    loginSucceeded: false,
                    loginFailed: true,
                    error: action.error,
               };
          case ACTIONS.CLEAR_LOGIN:
               return { ...INITIAL_STATE };
          case ACTIONS.LOGOUT_REQUEST:
               return {
                    logoutRequested: true,
                    logoutSucceeded: false,
                    logoutFailed: false,
               };
          case ACTIONS.LOGOUT_SUCCESS:
               return {
                    logoutRequested: false,
                    logoutSucceeded: true,
                    logoutFailed: false,
               };
          case ACTIONS.LOGOUT_FAILURE:
               return {
                    logoutRequested: false,
                    logoutSucceeded: false,
                    logoutFailed: true,
                    error: action.error,
               };
          case ACTIONS.SIGNUP_REQUEST:
               return {
                    signupRequested: true,
                    signupSucceeded: false,
                    signupFailed: false,
               };
          case ACTIONS.SIGNUP_SUCCESS:
               return {
                    signupRequested: false,
                    signupSucceeded: true,
                    signupFailed: false,
                    fetchUserAvatarAfterRegistration: true
               };
          case ACTIONS.SIGNUP_FAILURE:
               return {
                    signupRequested: false,
                    signupSucceeded: false,
                    signupFailed: true,
                    error: action.error,
               };
          case ACTIONS.FORGOTTEN_PASSWORD_REQUEST:
               return {
                    forgottenPasswordRequested: true,
                    forgottenPasswordSucceeded: false,
                    forgottenPasswordFailed: false,
               };
          case ACTIONS.FORGOTTEN_PASSWORD_SUCCESS:
               return {
                    forgottenPasswordRequested: false,
                    forgottenPasswordSucceeded: true,
                    forgottenPasswordFailed: false,
                    message: action.message,
               };
          case ACTIONS.FORGOTTEN_PASSWORD_FAILURE:
               return {
                    forgottenPasswordRequested: false,
                    forgottenPasswordSucceeded: false,
                    forgottenPasswordFailed: true,
                    error: action.error,
               };
          case ACTIONS.RESET_PASSWORD_REQUEST:
               return {
                    resetPasswordRequested: true,
                    resetPasswordSucceeded: false,
                    resetPasswordFailed: false,
               };
          case ACTIONS.RESET_PASSWORD_SUCCESS:
               return {
                    resetPasswordRequested: false,
                    resetPasswordSucceeded: true,
                    resetPasswordFailed: false,
                    message: action.payload,
               };
          case ACTIONS.RESET_PASSWORD_FAILURE:
               return {
                    resetPasswordRequested: false,
                    resetPasswordSucceeded: false,
                    resetPasswordFailed: true,
                    error: action.error,
               };
          default:
               return state;
     }
}
