import React from 'react';
import { PortletWrapper } from './Portlet.style';
import {COLORS} from "../../../constants/style.constants";

export const Portlet = ({
    title,
    content,
    sticky = false,
    customTitle = null,
    onClickEvent = null,
    additionalSection = null
}) => {

    const titleStyle = {
        position: sticky ? 'sticky' : undefined,
        top: sticky ? 0 : undefined,
        zIndex: sticky ? 9 : 1,
        backgroundColor: sticky ? COLORS.WHITE : undefined
    };

     return (
         <PortletWrapper>
             <div className="card" onClick={onClickEvent}>
                 <h3 className="card-title" style={titleStyle}>
                     {title}
                     {customTitle}
                 </h3>
                 {additionalSection}
                 <section>
                     <div className="card-body">{content}</div>
                 </section>
             </div>
         </PortletWrapper>
     );
};
