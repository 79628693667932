import React from 'react';

function PencilIcon({ color, activeColor, hovered }) {
     return (
          <svg
               xmlns="http://www.w3.org/2000/svg"
               width="14"
               height="14"
               className="pencil"
               fill="none"
               viewBox="0 0 14 14"
          >
               <path
                    fill={hovered ? activeColor : color}
                    d="M0 10.455v2.752h2.917l8.607-8.12-2.917-2.752L0 10.455zM13.772 1.928L11.956.215a.813.813 0 00-1.1 0L9.431 1.558l2.917 2.751 1.423-1.343a.706.706 0 000-1.038z"
               ></path>
          </svg>
     );
}

export default PencilIcon;
