import axios from 'axios';
import i18n from "i18next";

export const getToken = () => localStorage.getItem('token');
export const getLocale = (forceLocale) => {
     if (forceLocale) {
          return forceLocale;
     }

     return localStorage.getItem('translation');
};
export const setLocale = (language) => {
     const code = language && language.code ? language.code : 'en';

     localStorage.setItem('translation', code);
     i18n.changeLanguage(code);
};

export const getUrl = () =>
     process.env.NODE_ENV === 'development'
          ? process.env.REACT_APP_DEV_API_URL
          : process.env.REACT_APP_STAGE_API_URL;

const instance = axios.create({
     baseUrl: getUrl(),
});

instance.interceptors.request.use(request => {
     const language = getLocale();
     const accessToken = getToken();

     if (accessToken) {
          request.headers.Authorization = `Bearer ${accessToken}`
     }

     if (language && request.headers['A-Locale'] === undefined) {
          request.headers['A-Locale'] = language;
     }

     return request
});


export { instance };
export default instance;
