import styled from 'styled-components';
import { COLORS, FONT, SIZE } from '../../../constants/style.constants';

const DashboardWrapper = styled.div`
     padding-bottom: 229px;

     h3 {
          font-weight: ${FONT.MEDIUM};
          font-size: ${SIZE.BIG};
          line-height: 22px;
          color: ${COLORS.DARK_BLUE};
          margin-bottom: 20px;
     }

     .info-block {
          display: flex;
          align-items: center;
          margin-bottom: 30px;
          img {
               border-radius: 3px;
          }

          .text-block {
               padding-left: 15px;
          }
     }

     .card-body {
          padding: 30px;

          .card-title {
               font-weight: ${FONT.MEDIUM};
               font-size: ${SIZE.BIG};
               line-height: 22px;
               color: ${COLORS.DARK_BLUE};
          }

          .card-subtitle {
               font-weight: ${FONT.REGULAR};
               font-size: ${SIZE.INCREASED};
               line-height: 19px;
               color: ${COLORS.TEXT_GREY};
          }

          .btn-outline-primary {
               border: 1px solid #e1e4ec;
               border-radius: 4px;
               color: ${COLORS.TEXT_GREY};
               width: 100%;

               &:hover {
                    color: ${COLORS.WHITE};
                    border: 1px solid ${COLORS.BLUE};
                    background-color: ${COLORS.BLUE};
               }
          }
     }

     .speaker-block {
          margin-top: 20px;
     }
`;

export default DashboardWrapper;
