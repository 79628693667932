import * as ACTIONS from '../actions';

export const errorsDisplay = (payload) => ({
     type: ACTIONS.ERRORS_DISPLAY,
     payload,
});

export const errorsRefresh = () => ({ type: ACTIONS.ERRORS_REFRESH });

export const INITIAL_STATE = {
     errors: [],
     message: null,
};

export default function errorsReducer(state = INITIAL_STATE, action) {
     switch (action.type) {
          case ACTIONS.ERRORS_DISPLAY:
               return {
                    errors: action.payload.errors,
                    message: action.payload.message
               };
          case ACTIONS.ERRORS_REFRESH:
               return {
                    errors: [],
                    message: null
               };
          default:
               return state;
     }
}
