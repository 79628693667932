import styled from 'styled-components';

const HeaderToolbarWrapper = styled.div`
     display: flex;
     align-items: center;

     transition: all 0.15s ease;

     @media only screen and (max-width: 1023px) {
          .not-sr {
               display: none;
          }
     }

     @media only screen and (min-width: 1024px) {
          .toolbar-expanded {
               background-color: red;
               /* visibility: hidden; */
          }
     }
`;

export default HeaderToolbarWrapper;
