import React from 'react';

function TrashCanIcon() {
     return (
          <svg
               xmlns="http://www.w3.org/2000/svg"
               width="16"
               height="18"
               fill="none"
               viewBox="0 0 16 18"
          >
               <path
                    fill="#DB4A58"
                    d="M14.328 2.11h-3.164v-.528C11.164.71 10.454 0 9.582 0H6.418c-.872 0-1.582.71-1.582 1.582v.527H1.672C.8 2.11.09 2.82.09 3.691v1.055c0 .291.236.527.527.527h.57l.927 11.284v.004C2.19 17.381 2.866 18 3.69 18h8.62c.824 0 1.501-.619 1.576-1.439v-.004l.928-11.284h.569a.527.527 0 00.527-.527V3.691c0-.872-.71-1.582-1.582-1.582zm-8.437-.528c0-.29.236-.527.527-.527h3.164c.29 0 .527.236.527.527v.527H5.891v-.527zm6.944 14.886a.525.525 0 01-.525.477H3.69a.525.525 0 01-.525-.477l-.92-11.195h11.51l-.92 11.195zm2.02-12.25H1.146v-.527c0-.29.236-.527.527-.527H14.328c.29 0 .527.237.527.527v.528z"
               ></path>
               <path
                    fill="#DB4A58"
                    d="M8 6.328a.527.527 0 00-.527.527v7.454a.527.527 0 001.054 0V6.855A.527.527 0 008 6.328zM11.164 6.328a.527.527 0 00-.527.527v7.454a.527.527 0 001.054 0V6.855a.527.527 0 00-.527-.527zM4.836 6.328a.527.527 0 00-.527.527v7.454a.527.527 0 001.054 0V6.855a.527.527 0 00-.527-.527z"
               ></path>
          </svg>
     );
}

export default TrashCanIcon;
