import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SHOW_TOOLBAR, HIDE_TOOLBAR } from '../../../redux/actions';
import MoreIcon from '../../../assets/icons/metronic/more-icon';
import { MeatballMenuWrapper } from './MeatballMenu.styles';

export const MeatballMenu = memo(() => {
     const dispatch = useDispatch();
     const { toolbarShown } = useSelector((state) => state.ui);

     const handleClick = useCallback(() => {
          if (toolbarShown) {
               return dispatch({ type: HIDE_TOOLBAR });
          }

          dispatch({ type: SHOW_TOOLBAR });
     }, [toolbarShown]);

     return (
          <MeatballMenuWrapper onClick={handleClick}>
               <MoreIcon color="#d1d5e2" />
          </MeatballMenuWrapper>
     );
});
