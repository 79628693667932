import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { COLORS, SIZE } from '../../../constants/style.constants';

export const ListContainer = styled(Link)`
     text-decoration: none !important;
     color: inherit;
`;

export const CollapsibleListWrapper = styled('div')`
     color: ${COLORS.TEXT_GREY};
     font-size: ${SIZE.TINY};
     padding: 9px 30px;

     transition: all 0.15s ease;

     .wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
     }

     .fa-chevron-right {
          visibility: ${({ close }) => (close ? 'hidden' : 'visible')};
          height: 10px;
          transform: rotate(0);

          transition: transform 0.15s ease;

          &.down {
               transform: rotate(90deg);
          }
     }

     &:hover {
          background-color: ${COLORS.PALE_BLUE};
          color: ${COLORS.BLUISH};
     }
`;

export const ContainerWrapper = styled('div')`
     color: ${COLORS.TEXT_GREY};
     display: ${({ collapse }) => (collapse ? 'flex' : 'none')};
     flex-direction: column;
     justify-items: center;
     transition: all 0.15s ease;
     font-size: ${SIZE.TINY};

     [role='button'] {
          padding-left: 40px;
     }

     .collapsible-list-item {
          display: flex;
          align-content: center;

          svg {
               display: flex;
               align-self: center;
               margin-right: 15px;
          }

          &:hover,
          &:active {
               svg g [fill] {
                    fill: ${COLORS.BLUE};
                    opacity: 0.8;
               }
          }

          .sub-item {
               display: flex;
               align-self: center;
          }
     }
`;
