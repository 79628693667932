import React, {memo, useEffect, useState} from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ImageCropper } from './ImageCropper';

export const ImageCropperModal = memo(
     ({ show, handleClose, url, picture, onCrop }) => {
          const { t } = useTranslation();

         const [cropper, setCropper] = useState(null);

         useEffect(() => {
             // Disable scrolling on main page
             document.body.style.overflow = show ? 'hidden' : 'unset';
         }, [show]);

         const handleSetCropper = (cropper) => {
             setCropper(cropper);
         };

          const handleCropEnd = () => {
              const data = cropper.getData();

                onCrop({
                    x: data.x,
                    y: data.y,
                    width: data.width,
                    height: data.height,
                    canvas: cropper.getCroppedCanvas().toDataURL()
                });
                handleClose();
          };

          return (
               <Modal
                    show={show}
                    onHide={handleClose}
                    container={document.getElementById('root')}
                    size="lg"
               >
                    <Modal.Header closeButton>
                         <Modal.Title>{t('picture_uploader.upload_image')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                         <ImageCropper
                              src={url}
                              imageType={picture && picture.type}
                              handleSetCropper={handleSetCropper}
                         />
                    </Modal.Body>
                    <Modal.Footer>
                         <Button variant="secondary" onClick={handleClose}>
                              {t('buttons.cancel')}
                         </Button>
                         <Button
                              variant="primary"
                              type="button"
                              onClick={handleCropEnd}
                         >
                              {t('buttons.upload')}
                         </Button>
                    </Modal.Footer>
               </Modal>
          );
     }
);
