import styled from 'styled-components';
import { Breadcrumb } from 'react-bootstrap';
import { COLORS, FONT, SIZE } from '../../../constants/style.constants';

export const BreadcrumbWrapper = styled('div')`
     display: flex;
     align-items: center;

     .btn-default {
          color: ${COLORS.PLACEHOLDER_GREY} !important;
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          margin-right: 15px;
          max-height: 36px;
          padding: 9px 20px;
          
          &:hover,
          :active {
               background-color: ${COLORS.LIGHTER_BLUE} !important;
          }

          svg {
               height: 12px;
               margin-right: 5px;
          }
     }

     @media only screen and (max-width: 1320px) {
          justify-content: space-between;
          align-items: stretch;
          flex-wrap: wrap;
          .btn-default {
               display: none;
          }
     }

     @media only screen and (max-width: 1320px) and (min-width: 1023px) {
          margin-top: 20px;
     }
`;

export const StyledBreadcrumb = styled(Breadcrumb)`
     .breadcrumb {
          background-color: transparent;
          align-items: center;
          margin: 0;
          padding: 0;
          margin-bottom: 20px;

          .breadcrumb-item {
               &:first-child {
                    /* text-transform: capitalize; */
                    margin-right: 15px;
                    font-weight: ${FONT.MEDIUM};
                    font-size: ${SIZE.BIG};
                    line-height: 22px;
                    color: ${COLORS.DARK_BLUE};
               }
          }

          a {
               padding-right: 6px;
               text-decoration: none;
               font-weight: ${FONT.MEDIUM};
               font-size: ${SIZE.NORMAL};
               line-height: 18px;
               color: ${COLORS.LAVANDER};
               text-decoration: none;
               /* text-transform: capitalize; */

               &:hover {
                    color: ${COLORS.BLUE};
               }
          }

          svg {
               margin-right: 8px;
          }

          .separator {
               padding-right: 6px;
          }

          .separator:after {
               display: block;
               width: 4px;
               height: 4px;
               border-radius: 50%;
               content: ' ';
               background: ${COLORS.DARKER_GREY};
          }
     }
     @media only screen and (max-width: 1320px) {
          .breadcrumb {
               margin-bottom: 15px;
               a {
                    font-size: ${SIZE.TINY};
               }
               .breadcrumb-item {
                    &:first-child {
                         font-size: ${SIZE.NORMAL};
                    }
               }
          }
     }

     @media only screen and (max-width: 1023px) {
          .breadcrumb {
               margin-top: 15px;
          }
     }

     @media only screen and (max-width: 1023px) {
          .breadcrumb {
               margin-top: 15px;
          }
     }
`;
