import React, { forwardRef, useState, useEffect } from 'react';
import Tags from '@yaireo/tagify/dist/react.tagify';

// Tagify settings object
const baseTagifySettings = {
     blacklist: [],
     editTags: 0,
     dropdown: {
          enabled: 0,
     },
     callbacks: {},
};

export const TagField = forwardRef(
     (
          {
               withIds = false,
               validateEmail = false,
               readOnlyValues = false,
               enforceWhitelist = true,
               placeholder,
               value,
               label,
               name,
               initialValue = [],
               suggestions = [],
               onChange,
               delimiters = ','
          },
          ref
     ) => {
          const handleChange = (e) => {};

          const handleBlurChange = (e) => {
               e.detail.tagify.DOM.input.textContent = '';
               e.detail.tagify.DOM.input.innerHTML = '';
               e.detail.tagify.DOM.input.innerText = '';
               e.detail.tagify.state.inputText = '';
          };

          const [settings, setSettings] = useState({
               ...baseTagifySettings,
               enforceWhitelist,
               placeholder,
               skipInvalid: true,
               delimiters,
               whitelist: suggestions,
               pattern: validateEmail ? /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ : '',
               originalInputValueFormat: withIds
                    ? (valuesArr) => valuesArr.map(({ id }) => id)
                    : (valuesArr) => valuesArr.map((item) => item.value),
               callbacks: {
                    add: handleChange,
                    remove: handleChange,
                    blur: handleBlurChange,
                    edit: handleChange,
                    invalid: handleChange,
                    click: handleChange,
                    focus: handleChange,
                    'edit:updated': handleChange,
                    'edit:start': handleChange,
               },
          });

          useEffect(() => {
               if (!settings) return;

               setSettings({
                    ...baseTagifySettings,
                    enforceWhitelist,
                    placeholder,
                    whitelist: suggestions,
                    dropdown: {
                         className: 'tagify-dropdown',
                    },
                    pattern: validateEmail ? /\S+@\S+\.\S+/ : '',
                    originalInputValueFormat: withIds
                         ? (valuesArr) => valuesArr.map(({ id }) => id)
                         : (valuesArr) => valuesArr.map((item) => item.value),
                    callbacks: {
                         add: handleChange,
                         remove: handleChange,
                         blur: handleChange,
                         edit: handleChange,
                         invalid: handleChange,
                         click: handleChange,
                         focus: handleChange,
                         'edit:updated': handleChange,
                         'edit:start': handleChange,
                    },
               });
          }, [readOnlyValues]);

          const checkReadOnlyValues = () => {
               const tagElements = document.querySelectorAll(
                    '.tagify__tag-text'
               );

               tagElements.forEach((element) => {
                    const parentElement = element && element.closest('tag');
                    parentElement && parentElement.removeAttribute('readonly');
               });

               const tagElement = Array.from(tagElements).find((el) =>
                    readOnlyValues.includes(el.textContent)
               );

               const parentElement = tagElement && tagElement.closest('tag');

               parentElement && parentElement.setAttribute('readonly', true);
          };

          readOnlyValues && checkReadOnlyValues();

          return (
               <Tags
                    tagifyRef={ref}
                    settings={settings}
                    // initialValue={initialValue}
                    value={value || ''}
                    className="tags"
                    onChange={onChange}
               />
          );
     }
);
