import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import monitorReducersEnhancer from './enhancers/monitorReducer';
import rootReducer from './reducers';
import rootSaga from './sagas';
import { HISTORY } from '../constants';

export default function configureStore() {
     let store = null;

     const sagaMiddleware = createSagaMiddleware();
     const middlewares = [routerMiddleware(HISTORY), sagaMiddleware];
     const middlewareEnhancer = applyMiddleware(...middlewares);
     const enhancers = [middlewareEnhancer, monitorReducersEnhancer];
     const composedEnhancers = composeWithDevTools(...enhancers);

     store = createStore(rootReducer(HISTORY), {}, composedEnhancers);

     if (process.env.NODE_ENV === 'development') {
          module.hot.accept('./reducers', () =>
               store.replaceReducer(rootReducer(HISTORY))
          );
     }

     return { ...store, runSaga: sagaMiddleware.run(rootSaga) };
}
