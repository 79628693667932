import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import {AvatarLoader} from "../loader/AvatarLoader";

export const Avatar = memo(({ src, width, height, showLoader = false, ...restProps }) => {
     const { t } = useTranslation();

     if (!src && showLoader) {
          return (<AvatarLoader width={width} height={height} {...restProps} />);
     }

     return (
          <img src={src} alt={t('user_settings.user_avatar')} width={width} height={height} {...restProps} />
     );
});
